const baseUrl = '/app';
const accountUrl = `${baseUrl}/account`;
const shipperUrl = `${baseUrl}/shipper`;
const carrierUrl = `${baseUrl}/carrier`;
const employeeUrl = `${baseUrl}/employee`;
const resourcesUrl = `${baseUrl}/resources`;

export const route = {
  webUrls: {
    whatsApp: 'https://api.whatsapp.com/send?text=',
  },
  login: `${baseUrl}/login`,
  chooseAccountType: `${accountUrl}/select-type`,
  createAccount: `${accountUrl}/sign-up`,
  welcome: 'welcome',
  registrationOtp: `${accountUrl}/verify-otp`,
  forgotPassword: `${accountUrl}/recover/forgot-password`,
  updatePassword: `${accountUrl}/recover/create-new-pin`,

  shipper: {
    indent: `${shipperUrl}/indent`,
    createIndent: `${shipperUrl}/indent/create`,
    users: `${shipperUrl}/users`,
    createUser: `${shipperUrl}/users/create`,
    profile: `${shipperUrl}/profile`,
    logout: `${shipperUrl}/logout`,
  },

  carrier: {
    users: `${carrierUrl}/account/users`,
    createUser: `${carrierUrl}/account/users/create`,
    indent: `${carrierUrl}/indent`,
    trips: `${carrierUrl}/trips`,
    active: `${carrierUrl}/trips/active`,
    delivered: `${carrierUrl}/trips/delivered`,
    balance: `${carrierUrl}/trips/balance`,
    paid: `${carrierUrl}/trips/paid`,
    truck: `${carrierUrl}/truck`,
    addTruck: `${carrierUrl}/truck/create`,
    account: `${carrierUrl}/account`,
    profile: `${carrierUrl}/account/profile`,
    tutorials: `${carrierUrl}/tutorials`,
    logout: `${carrierUrl}/account/logout`,
  },

  employee: {
    login: `${employeeUrl}/login`,
    indent: `${employeeUrl}/indent`,
    createIndent: `${employeeUrl}/indent/create`,
    carrier: `${employeeUrl}/carrier`,
    createCarrier: `${employeeUrl}/carrier/create`,
    leadCarriers: `${employeeUrl}/carrier/lead`,
    createCarrierUser: `${employeeUrl}/carrier/user/create`,
    shipper: `${employeeUrl}/shipper`,
    createShipper: `${employeeUrl}/shipper/create`,
    leadShippers: `${employeeUrl}/shipper/lead`,
    createShipperUser: `${employeeUrl}/shipper/user/create`,
    users: `${employeeUrl}/users`,
    addEmployee: `${employeeUrl}/users/create`,
    carrierCityPreference: '/city-preference',
    carrierTruckTypePreference: '/truck-type-preference',
    trips: `${employeeUrl}/trips`,
    confirmed: `${employeeUrl}/trips/confirmed`,
    loading: `${employeeUrl}/trips/loading`,
    intransit: `${employeeUrl}/trips/intransit`,
    unloading: `${employeeUrl}/trips/unloading`,
    delivered: `${employeeUrl}/trips/delivered`,
    invoiced: `${employeeUrl}/trips/invoiced`,
    closed: `${employeeUrl}/trips/closed`,
    payments: `${employeeUrl}/payments`,
    shipperAdvance: `${employeeUrl}/payments/shipper-advance`,
    shipperBalance: `${employeeUrl}/payments/shipper-balance`,
    carrierAdvance: `${employeeUrl}/payments/carrier-advance`,
    carrierBalance: `${employeeUrl}/payments/carrier-balance`,
    bankPending: `${employeeUrl}/payments/pending-transactions`,
    statement: `${employeeUrl}/payments/statement`,
    report: `${employeeUrl}/report`,
    profile: `${employeeUrl}/profile`,
    logout: `${employeeUrl}/profile/logout`,
    trucks: `${employeeUrl}/trucks`,
  },

  resources: {
    shippers: `${resourcesUrl}/shippers`,
    shipperContact: `${resourcesUrl}/shipper-contact`,
    shipperStatement: `${resourcesUrl}/shipperStatement`,
    employees: `${resourcesUrl}/employees`,
    carriers: `${resourcesUrl}/carriers`,
    trucks: `${resourcesUrl}/trucks`,
    trips: `${resourcesUrl}/trips`,
    truckType: `${resourcesUrl}/truckType`,
    truckTypeFilter: `${resourcesUrl}/trucktype-filter`,
    drivers: `${resourcesUrl}/drivers`,
    materials: `${resourcesUrl}/materials`,
    cities: `${resourcesUrl}/cities`,
    country: `${resourcesUrl}/country`,
    addTruck: `${resourcesUrl}/addTruck`,
    indentStatus: `${resourcesUrl}/indent-status`,
    roles: `${resourcesUrl}/roles`,
    companyComment: `${resourcesUrl}/company-comment`,
    truckComment: `${resourcesUrl}/truck-comment`,
    indentComment: `${resourcesUrl}/indent-comment`,
    tripComment: `${resourcesUrl}/trip-comment`,
    tripShowMore: `${resourcesUrl}/trip-showmore`,
    tripCall: `${resourcesUrl}/trip-call`,
    bankBalance: `${resourcesUrl}/bankBalance`,
    searchCompany: `${resourcesUrl}/searchCompany`,
    tripTimeUpdate: `${resourcesUrl}/tripTimeUpdate`,
  },

  screen: {
    REGISTRATION: 'REGISTRATION',
    LOGIN: 'LOGIN',
    UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  }
};
